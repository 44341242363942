/* second section */

.sec_business {
  background-color: #f1f1f1;
  height: 840px;
}
.image_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 15px;
}

.text_container2 {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 18px 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.text_container2 p {
  margin: 0;
  color: #1a1a1a;
  /* font-size: 14px; */
  font-weight: 400;
  text-align: justify;
}
.text_container2 h2 {
  margin-bottom: 13px;
  color: #000;
  font-weight: 600;
  text-align: justify;
}

@media only screen and (max-width: 980px) {
  /*for second section*/
  .image {
    margin: 20px auto;
    max-width: 100%;
  }
  .image_container {
    padding: 30px 15px;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .text_container2 {
    position: static;
    transform: none;
    margin: 20px auto;
  }

  .sec_business {
    height: auto;
  }
}
