/* third section */
/* .investments {
  background-color: red;
} */
.third {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  /* background-color: #f1f1f1; */
}
.text_container3 {
  background-color: white;
  padding: 18px 20px;
  position: absolute;
  margin-top: -413px; /*Adjust this value to move the text up or down*/
  z-index: 1;
  /* height: 320px; */
  width: 600px;
  margin-left: 160px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.text_container3 h2 {
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 13px;
}

.text_container3 p {
  /* font-size: 14px; */
  font-weight: 400;
  color: #0a0a0a;
  text-align: justify;
}

.image3 {
  margin-left: 450px;
  width: 800px; /* Adjust the desired width */
  height: 400px;
}
.separator {
  height: 170px;
}

@media only screen and (max-width: 980px) {
  /*for third section*/
  .third {
    flex-direction: column;
    padding: 0 15px;
  }

  .separator {
    height: 45px;
  }

  .text_container3 {
    position: relative;
    width: auto;
    margin: 0;
    height: auto;
  }

  .image3 {
    margin: 20px auto;
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1218px) {
  .text_container3 {
    margin-left: 42px;
  }

  .image3 {
    margin-left: 260px;
    height: 324px;
  }
}
