/* First section */
.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 60px 15px;
}

.text_container {
  background-color: white;
  padding: 18px 20px;
  position: absolute;
  /* margin-top: 100px; Adjust this value to move the text up or down */
  z-index: 1;
  /* height: 212px; */
  width: 530px;
  margin-left: 220px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.text_container h2 {
  font-weight: 600;
  color: #000;
  /* text-transform: capitalize; */
  padding: 2px;
  margin-bottom: 13px;
}
.text_container p {
  /* font-size: 14px; */
  font-weight: 400;
  color: #0a0a0a;
  text-align: justify;
}
.image {
  margin-left: 450px;
  width: 100%;
  max-width: 800px; /* Adjust the desired width */
  /* max-height: 380px; */
  height: auto;
}

@media only screen and (max-width: 980px) {
  html,
  body {
    width: 100%;
    overflow-x: hidden;
    padding: 0;
  }
  /*for first section*/
  .container {
    flex-direction: column;
    align-items: center;
    padding: 0px 15px;

    /* padding: 130px 165px; */
  }
  .text_container {
    width: 100%;
    margin: 20px auto;
    position: static;
    height: auto;
  }
  .image {
    margin: 0;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1218px) {
  .text_container {
    margin-left: 80px;
  }
  .image {
    margin-left: 200px;
  }
}
