/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,700&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Roboto", sans-serif; */
  /* font-family: "TiemposText", Sans-serif; */
  /* font-family: "Merriweather", serif; */
  font-family: "Open Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

p {
  font-size: 17px;
  font-weight: 400;
  color: #333;
  line-height: 1.5;
}
h1 {
  font-size: 43px;
  font-weight: 600;
  color: #7a7a7a;
}
h3 {
  font-size: 32px;
  font-weight: 600;
  color: #333;
}

h2 {
  font-size: 35px;
}
@media only screen and (max-width: 768px) {
  html,
  body {
    width: 100%;
    overflow-x: hidden;
    padding: 0;
  }
}
