.heading {
  color: #000;
  font-size: 35px;
  padding-top: 30px;
  margin-bottom: 50px;
  margin-left: 20px;
}

.sec_portfolio {
  margin: 10px 170px;
}
.portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 10px; */
  /* padding-bottom: 40px; */
  padding-left: 15px;
  /* padding-right: 15px; */
  /* border: 3px solid red; */
  margin-bottom: 50px;
}
.imagesect {
  width: 180px;
  /* height: 80px; */
  height: auto;
  /* padding-top: 15px; */
}
.textsect {
  /* padding-top: 30px; */
  /* padding-bottom: 10px; */
  padding-left: 50px;
  padding-right: 50px;
  width: 800px;
  /* height: 200px; */
  text-align: left;
  line-height: 1.5;
}
.textsect h5 {
  font-size: 23px;
  font-weight: 600;
  /* margin-bottom: 10px; */
}
.textsect a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #868e96;
}
.textsect p {
  font-size: 16px;
  font-weight: 400;
  padding-top: 5px;
  color: #050505;
}

.company_logo {
  width: 100%;
}

.company_link:hover {
  color: #1971c2;
}

@media only screen and (max-width: 668px) {
  .portfolio {
    flex-direction: column;
    padding: 0px;
  }

  .textsect {
    padding: 10px 18px;
    width: 100%;
  }
  .imagesect {
    margin-bottom: 10px;
  }

  .sec_portfolio {
    margin: 0;
  }
}

@media only screen and (min-width: 668px) and (max-width: 1020px) {
  .portfolio {
    /* flex-direction: column; */
    padding: 0px;
  }
  .sec_portfolio {
    margin: auto 30px;
  }
}
